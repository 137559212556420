<template>
  <div class="sign-up-wrap sign-up-wrap-email purple-bg pt-4 pb-2">
    <div class="small-container">
      <div class="sign-up-wrap-email--logo">
        <a>
          <img src="@/assets/images/general/wear-it-logo.svg"/>
        </a>
      </div>
      <div class="sign-up-wrap--email">
        <div class="alert alert-danger" role="alert" v-if="errorRegister" v-text="errorMessage"></div>
        <div class="alert alert-success" role="alert" v-if="successRegister" v-text="successMessage"></div>
        <h2 class="h2-style text-center">{{ __t('sign-up.title') }}</h2>
        <form @submit.prevent="handleForm" @input="validateForm">
          <a :href="fbLink" class="btn-default btn-classic" target="_blank">{{ __t('sign-up.with-facebook') }}</a>
          <div class="form-group text-center">
            OR
          </div>
          <div class="form-group">
            <!-- <label for="email">{{ __t('sign-up.email') }}</label> -->
            <input
              :placeholder="__t('sign-up.email')"
              type="text"
              class="form-control"
              @keydown="clearTo"
              @keyup="validateEmail(user.email)"
              :class="{ 'is-invalid': !validate.email }"
              required
              @blur="validateEmail(user.email, 0)"
              v-model="user.email"
              id="email"
              aria-describedby="email"/>
            <small class="form-text invalid-feedback">{{ __t('sign-up.not-valid-email') }}</small>
          </div>

          <div class="form-group">
            <!-- <label for="username">{{ __t('sign-up.username') }}</label> -->
            <input
              :placeholder="__t('sign-up.username')"
              type="text"
              class="form-control"
              @keydown="clearTo"
              @keyup="validateUsername(user.username)"
              :class="{ 'is-invalid': !validate.username }"
              required v-model="user.username"
              id="username"
              maxlength="36"
              aria-describedby="username"/>
            <small class="form-text invalid-feedback"
                   v-text="(user.username && user.username.length > 36) ? __t('username-max-length') : __t('sign-up.username-not-empty')"></small>
          </div>

          <div class="form-group">
            <!-- <label for="exampleInputPassword1">{{ __t('sign-up.password') }}</label> -->
            <input
              :placeholder="__t('sign-up.password')"
              type="password"
              class="form-control"
              :class="{ 'is-invalid': !validate.password }"
              @keydown="clearTo"
              @keyup="validatePassword(user.password)"
              required
              v-model="user.password"
              id="exampleInputPassword1"/>
            <small class="form-text invalid-feedback">{{ __t('sign-up.not-valid-pass') }}</small>
          </div>

          <div class="yellow-button">
            <button type="submit" class="btn-default" :disabled="!valid">{{ __t('sign-up.submit') }}</button>
          </div>
          <!-- <small class="d-flex flex-wrap">
            {{ __t('sign-up.terms-description') }}&nbsp;
            <router-link class="text-underline" :to="{ name: 'Page', params: { slug: 'terms' }}"
                         v-text="__t('sign-up.terms-privacy')"></router-link>
            .
          </small> -->
          <h5 class="h5-style-lg">
            {{ __t('sign-up.terms-description') }}&nbsp;
            <router-link class="text-underline" :to="{ name: 'Page', params: { slug: 'terms' }}"
                         v-text="__t('sign-up.terms-privacy')"></router-link>
            .
          </h5>
        </form>
      </div>
      <h5 class="h5-style-lg">{{ __t('sign-up.already-have-account') }}
        <router-link class="text-underline" :to="{ name: 'Login' }">here</router-link>
        .
      </h5>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  metaInfo: {
    title: 'Signup',
    meta: [
      {
        name: 'description',
        content: 'Let our inclusive community help you decide what to wear! Express your personal style and share your looks on the app'
      }
      // Add other meta tags as needed
    ]
  },
  mixins: [],
  data () {
    return {
      loading: false,
      valid: false,
      errorMessage: '',
      errorRegister: false,
      successMessage: '',
      successRegister: false,
      user: {
        email: null,
        username: null,
        password: null,
        password_confirmation: null
      },
      validate: {
        email: true,
        username: true,
        password: true
      },
      settimeout: null,
      fbLink: '#'
    }
  },
  methods: {
    ...mapActions('auth', ['register', 'fbAuthUlr']),
    handleForm () {
      if (!this.loading) {
        this.loading = true
        this.user.password_confirmation = this.user.password
        this.register(this.user).then(resp => {
          if (resp.errors) {
            for (let err in resp.errors) {
              this.errorMessage = resp.errors[err][0]
              break
            }
            this.errorRegister = true
            this.loading = false
          } else {
            this.errorRegister = false
            this.successMessage = this.__t('sign-up.success-message')
            this.successRegister = true
            this.settimeout = setTimeout(() => {
              this.$router.push({ name: 'Login' })
            }, 4000)
          }
        }).catch((err) => {
          if (err) {
            this.successRegister = false
            this.errorMessage = 'Please try again.'
            this.errorRegister = true
          }
          this.loading = false
        })
      }
    },
    validateEmail (email, s = null) {
      this.settimeout = setTimeout(() => {
        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        this.validate.email = re.test(email)
        // let data = this.user.email.trim(' ')
        // this.validate.username = data && data !== ''
        this.validateForm()
      }, s || 2000)
    },
    validatePassword () {
      if (this.user.password?.length > 0) {
        this.settimeout = setTimeout(() => {
          let data = this.user.password.trim(' ')
          this.validate.password = (data && data !== '' && data.length > 7)
          this.validateForm()
        }, 2000)
      }
    },
    validateUsername () {
      this.settimeout = setTimeout(() => {
        let data = this.user.username.trim()
        this.validate.username = data && data !== '' && data.length < 37
        this.validateForm()
      }, 2000)
    },
    validateForm () {
      this.valid = this.validate.email && this.validate.username && this.validate.password
    },
    clearTo () {
      clearTimeout(this.settimeout)
    }
  },
  created () {
    this.fbAuthUlr().then(resp => {
      this.fbLink = resp.facebook
    })
  },
  beforeDestroy () {
    this.clearTo()
  }
}
</script>
